<template>
    <input type="checkbox" id="nav-toggle">
      <SideBar></SideBar>
        <div class="main-content">
                <Header>
            </Header>
            <main>
                <div class="recent-grid ">
                    <div class="card">
                        <div class="card-header">
                            <h2>My bids</h2>
                        </div>
                        <div class="card-body">
                        <div class="table-responsive">
                            <table width="100%">
                                <thead>
                                    <tr>
                                        <td>NO:</td>
                                        <td>Order ID</td>
                                        <td>Order Title</td>
                                        <td>Category</td>
                                
                                        <td>Budget($)</td>
                                        <td>Due Time</td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(order, index) in myBids" :key="order.id">
                                        <td>{{ index + 1 }}</td>
                                        <td>{{ order.id}}</td>
                                        <td>{{ order.orderTitle }}</td>
                                        <td>{{ order.orderCategory }}</td>
                                  
                                        <td>{{ order.payment }}</td>
                                        <td>{{ calculateTimeRemaining(order.dueDate, order.dueTime) }}</td>
                                        <td @click.prevent="cancelBid()" style="color:red">cancel</td>
                                        <td> 
                                            <router-link :to="{ name: 'order-view', params: {id: order.id}}">
                                                View Details
                                            </router-link>
                                        </td>
                                    </tr>
                                   
                                </tbody>
                            </table>
                        </div>
                        <div class="cards-responsive">
                            <!-- Map orders to cards -->
                            <div v-for="(order, index) in myBids" :key="order.id" class="order-card">
                                <div class="order-card-content">
                                    <p><strong>NO:</strong> {{ index + 1 }}</p>
                                    <p><strong>Order ID:</strong> {{ order.id }}</p>
                                    <p><strong>Order Title:</strong> {{ order.orderTitle }}</p>
                                    <p><strong>Category:</strong> {{ order.orderCategory }}</p>
                                    <p><strong>Budget($):</strong> {{ order.payment }}</p>
                                    <p><strong>Due Time:</strong> {{ calculateTimeRemaining(order.dueDate, order.dueTime) }}
                                    </p>
                                    <button @click.prevent="cancelBid()" class="btn btn-info btn-take" id="btn-take"
                                                style="background-color: red;">
                                                cancel<i class="fa-solid fa-arrow-right"></i>
                                    </button>
                                    <router-link :to="{ name: 'order-view', params: { id: order.id } }">
                                        View Details
                                    </router-link>
                                </div>
                            </div>
                            <!-- Repeat the above card structure for each order in clientOrders -->
                        </div>
                        </div>
                    </div>
            </div>
            </main>
        </div>
</template>

<script>
import SideBar from "@/components/core/SideBar.vue";
import Header from "@/components/core/Header.vue";
import {db} from "@/firebase";
import { getFirestore, doc,deleteDoc, collection,  getDocs} from "firebase/firestore"; 
import { getAuth, onAuthStateChanged} from "firebase/auth";
export default {
    components: {
        SideBar, 
        Header
    },
    name: "myBids",
    data () {
        return {
            available: null,
            profileMenu: null,
            myBids: [],
            orderRef: null,

        }
    },
    methods: {
        toggleAvailable(){
            this.available= !this.available
        },
        toggleProfileMenu(){
            this.profileMenu= !this.profileMenu
        },
        cancelBid(){
            onAuthStateChanged(getAuth(), async (user) => {
                const userRef = doc(db, 'users', user.uid);
                const orderRef = collection(userRef, 'myBids');  
                const deleteMyBids = doc(orderRef, this.orderId);
                await deleteDoc(deleteMyBids);

                const ordersRef = doc(db, 'tobebidded_orders', this.orderId); 
                const bidRef = collection(ordersRef, 'bids');  
                const deletebid = doc(bidRef, user.uid);
                await deleteDoc(deletebid);
                alert("bid Canceled!");
            });
        },
        calculateTimeRemaining(dueDate, dueTime) {
            const dueDateTime = new Date(`${dueDate}T${dueTime}`);
            const currentTime = new Date();
            if(dueDateTime <currentTime){
                return "Duration Ended";
            };
            const timeRemaining = dueDateTime - currentTime;
            const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
            const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

            return `${days}d ${hours}h ${minutes}m ${seconds}s`;
        }
    },
    computed: {
  },
 async created() {
    onAuthStateChanged(getAuth(), async (user) => {
          if (user) {
            const userRef = doc(db, 'users', user.uid);
            const ordersRef = collection(userRef, 'myBids');   
            try {
              const querySnapshot = await getDocs(ordersRef);
              this.myBids = querySnapshot.docs.map((doc) => doc.data());

              this.myBids.forEach(order => {
              this.orderId = order.id;
        });
             
            } catch (error) {
              console.error(error);
            }
          } else {
            console.log('No user is currently logged in.');
          }
        });
  }
}
</script>

<style scoped>
 :root {
    --main-color:#fff;
    --color-dark:#02060b;
    --text-grey:#999999;
}
  * {
    list-style-type:none;
    text-decoration: none;
}
.profile-menu {
    position: absolute;
    border-radius: 10px;
    top: 60px;
    right: 0; 
    width: 270px;
    background-color: #fff;
    box-shadow: 0 4px 0px -1px #79aae6;
  }
  .info {
    display: flex;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #fff;
  }
  .right {
    flex: 1;
    margin-left: 24;
  }
  i{
    padding-right:10px;
  }
.sidebar {
    width: 200px;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    background-color: #fff;
    z-index: 999;
    transition: margin-left 300ms;
}
.sidebar-brand{
    height: 90px;
    padding:1rem 0rem 1rem 2rem; 
}
.sidebar-brand span {
    display: inline-block;
    padding-right: 1rem;
}
.sidebar-menu {
    margin-top: 1rem;
}
.sidebar-menu li {
    width: 100%;
    margin-bottom: 1.3rem;
    padding-left: 2rem;
    font-size: 15px;
}
.sidebar-menu li .available{
    margin-left:3rem ;
}
.sidebar-menu li .available a span{
    margin-left: 3.1rem;
    background: #79aae6;
    border-radius: 50%;
    padding-left: .5rem;
    font-size: 1.5rem;
}
a .li-span{
    margin-left: 0rem;
    background: #79aae6;
    border-radius: 50%;
    font-size: 1.5rem;
    padding-left:.5rem;
}
.sidebar-menu a {
    display: block;
    color: #02060b;
    padding-bottom: 1rem;
}
.sidebar-menu a.active {
    color: #1c68c4;
    padding-top: 1rem;
    padding-bottom: 1rem;
} 
.sidebar-menu a span:first-child {
    font-size: 1.5rem;
    padding-right: 1rem;
} 
#nav-toggle:checked + .sidebar {
    width: 70px;
}
#nav-toggle:checked + .sidebar .sidebar-brand,
#nav-toggle:checked + .sidebar li {
   padding-left: 1rem;
   text-align: center;
}
#nav-toggle:checked + .sidebar li a{
    padding-left: 0rem;
 }
 #nav-toggle:checked + .sidebar li a span{
    padding-right: 1rem;
 }
#nav-toggle:checked + .sidebar .sidebar-brand h3 span:last-child,
#nav-toggle:checked + .sidebar li a span:last-child {
   display: none;
}
#nav-toggle:checked ~ .main-content{
    margin-left: 70px;
 }
#nav-toggle:checked ~ .main-content header{
   width: calc(100% -70px);
   left: 70px;
 }
.main-content {
    transition: margin-left 300ms;
    margin-left: 200px;
}

.main-content header{
    background: #fff;
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    box-shadow: 2px 2px 5px rgba(0,0,0, 0.2);
    position: fixed;
    left: 200px;
    right: 0;
    /* width: calc(100% -200px); */
    top: 0;
    z-index: 100;
    transition: left 300ms;

}
#nav-toggle{
    display: none;
}
header h3 {
    color: #222;
    margin-right: 50px;
}
header label span {
    font-size: 1.7rem;
    padding-left: 1rem ;
}

.user-wrapper{
    display: flex;
    align-items: center;
    float: right;
     
}
.user-wrapper span{
    font-size: 25px;
    margin-right: 30px;
     
}
.user-wrapper img{
    border-radius: 50%;
    margin-right: 1rem;
}
.user-wrapper small {
    display: inline-block;
    color:var(--text-grey) ;
    margin-top: -10px !important;
}
main{
    margin-top: 85px;
    padding: 2rem 1.5rem;
    background: #f1f5f9;
    min-height: calc(100vh -90px);

}
.cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;
    margin-top: 1rem;
}
.card-single {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    padding: 2rem;
    border-radius: 2px;
}
.card-single div:last-child span{
    color: #1c68c4;
    font-size:3.5rem;
}
.card-single div:first-child span{
    color: var(--text-grey);

}
.card-single:last-child{
    background: #1c68c4;
}
.card-single:last-child div:last-child span{
    color: #fff;
}
.card-single:last-child div:first-child span{
   color: #fff;
}
.recent-grid {
    margin-top: 3.5rem;

}
.card{
   background: #fff; 
}
.card-header,
.card-body {
    padding: 1rem;
    width: 100%;
}
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.card-header button {
    background: #1c68c4;
    border-radius: 10px;
    color: #fff;
    font-size: 15px;
    padding: .5rem 1rem;
    border: 1px solid #1c68c4;
}
table {
    border-collapse:collapse;
}
thead tr {
    border: 1px solid #1c68c4;;
}
tbody tr {
    border: 1px solid #1c68c4;;
}
thead td {
    font-weight: 700;
}
td {
    padding: .5rem 1rem;
    font-size: 15px;
    color: #222;
    border: 1px solid #1c68c4;;
   
}
td i {
    color: #1c68c4;
    padding-right: 1rem;
}
.table-responsive {
    width: 100%;
    overflow-x: auto;
}

@media only screen and (max-width:1200px){
   .sidebar {
        width: 70px;
    }
    .sidebar .sidebar-brand,
     .sidebar li {
       padding-left: 1rem;
       text-align: center;
    }
 .sidebar li a{
        padding-left: 0rem;
     }
   .sidebar li a span{
        padding-right: 1rem;
     }
 .sidebar .sidebar-brand h3 span:last-child,
  .sidebar li a span:last-child {
       display: none;
    }
     .main-content{
        margin-left: 70px;
     }
   .main-content header{
       width: calc(100% -70px);
       left: 70px;
     }
     .sidebar:hover {
        width: 200px;
        z-index: 200;
    }
    .sidebar:hover .sidebar-brand,
     .sidebar:hover li {
       padding-left: 2rem;
       text-align: left;
    }
 .sidebar:hover li a{
        padding-left: 1rem;
     }
   .sidebar:hover li a span{
        padding-right: 1rem;
     }
 .sidebar:hover .sidebar-brand h3 span:last-child,
  .sidebar:hover li a span:last-child {
       display: inline;
    }
}
 @media only screen and (max-width: 960px) {
    .cards  {
        grid-template-columns:repeat(3, 1fr);
    }
    .recent-grid {
        grid-template-columns: 60% 40%;
    }
 }
@media only screen and (min-width: 768px) {
    .cards-responsive {
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    .table-responsive {
        display: none;
    }
    .cards-responsive {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
        }

        .order-card {
            width: 100%;
            margin-bottom: 20px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            overflow: hidden;
            transition: transform 0.3s;
        }

        .order-card:hover {
            transform: scale(1.05);
        }

        .order-card-content {
            padding: 10px;
        }

    .cards {
        grid-template-columns: repeat(2, 1fr);
    }

    .recent-grid {
        grid-template-columns: 100%;
    }
    .search-wrapper{
        display: none;
    }
    .sidebar{
        left: -100% !important;
    }
    header h3 {
        display: flex;
        align-items: center;
    }
    header h3 label {
        display:inline-block;
        background:#1c68c4;
        padding-left: 0rem;
        margin-left: 1rem;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center !important;
    }
    header h3 span {
        text-align: center;
        padding-right: 1rem;
      
    }
    header h3 {
        font-size: 30px;
    }
    .main-content {
        width: 100%;
        margin-left: 0rem;
    }
    header {
        width: 100% !important;
        left: 0 !important;
        
    }
    #nav-toggle:checked + .sidebar {
        left: 0 !important;
        z-index: 100;
        width: 200px;
    }
    #nav-toggle:checked + .sidebar {
        width: 200px;
        z-index: 200;
    }
    #nav-toggle:checked + .sidebar .sidebar-brand,
    #nav-toggle:checked + .sidebar li {
       padding-left: 2rem;
       text-align: left;
    }
    #nav-toggle:checked + .sidebar li a{
        padding-left: 1rem;
     }
     #nav-toggle:checked + .sidebar li a span{
        padding-right: 1rem;
     }
     #nav-toggle:checked + .sidebar.sidebar-brand h3 span:last-child,
     #nav-toggle:checked + .sidebar li a span:last-child {
       display: inline;
    }
    #nav-toggle:checked ~ .main-content {
        margin-left: 0rem !important;
    }
 }
 @media only screen and (max-width: 560px) {
    .cards {
        grid-template-columns: 100%;
    }
 }
</style>