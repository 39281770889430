<template>
    <SideBar></SideBar>
    <div class="main-content">
        <Header>
        </Header>
        <main>
            <div class="recent-grid ">
                <div class="card-header">
                    <h2>Bids for order: {{ orderId }}</h2>
                </div>
                <div class="card-body">
                    <div class="card" v-for="bid in bids" :key="bid.id">
                        <router-link :to="{ name: 'client-view', params: { id: bid.userData.id, orderId: orderId } }">
                            <div class="head">
                                <div><img class="image" v-if="bid.userData.profileCoverFile"
                                        :src="bid.userData.profileCoverFile" alt='' style="border-radius: 50%;" /></div>
                                <div>
                                    <div class="stars">
                                        <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z">
                                            </path>
                                        </svg>
                                        <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z">
                                            </path>
                                        </svg>
                                        <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z">
                                            </path>
                                        </svg>
                                        <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z">
                                            </path>
                                        </svg>
                                        <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z">
                                            </path>
                                        </svg>
                                    </div>
                                    <p class="name">{{ bid.userData.firstName }} {{ bid.userData.lastName }}</p>
                                </div>
                            </div>
                        </router-link>
                        <p class="message">
                            {{ bid.bidtext }}
                        </p>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>
  
<script>
import { getDocs, getDoc, doc, getFirestore, collection } from 'firebase/firestore'
import SideBar from "@/components/core/SideBar.vue";
import Header from "@/components/core/Header.vue";
import { mapState } from 'vuex';
export default {
    components: {
        SideBar,
        Header
    },
    name: 'OrderBids',
    props: {
        id: {
            type: String,
            required: true
        }
    },
    computed: {
        ...mapState(['user']),
        orderId() {
            return this.$route.params.id
        },
    },
    data() {
        return {
            bids: [],
            userData: null,
        }
    },
    async created() {
        const db = getFirestore()
        const orderRef = doc(db, 'tobebidded_orders', this.orderId)
        const bidsRef = collection(orderRef, 'bids')
        const snapshot = await getDocs(bidsRef)
        const newBids = snapshot.docs.map(doc => doc.data());


        const promises = newBids.map(async (bid) => {
            const userRef = doc(collection(db, "users"), bid.freelancer);
            const userSnapshot = await getDoc(userRef);
            const userData = userSnapshot.data();

            bid.userData = {
                firstName: userData.firstName,
                lastName: userData.lastName,
                phoneNumber: userData.phoneNumber,
                profileCoverFile: userData.profileCoverFile,
                email: userData.email,
                id: userData.id,
            };
        });
        await Promise.all(promises);

        // Assign the completed data to the component property
        this.bids = newBids;
    }
}
</script>
<style scoped>
.card {
    background-color: rgba(243, 244, 246, 1);
    padding: 2rem;
    max-width: 320px;
    border-radius: 10px;
    box-shadow: 0 20px 30px -20px rgba(5, 5, 5, 0.24);
}

.head {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
}

.head .image {
    height: 4rem;
    width: 4rem;
    border-radius: 9999px;
    object-fit: cover;
    background-color: royalblue;
}

.stars {
    display: flex;
    justify-content: center;
    grid-gap: 0.125rem;
    gap: 0.125rem;
    color: rgba(34, 197, 94, 1);
}

.stars svg {
    height: 1rem;
    width: 1rem;
}

.name {
    margin-top: 0.25rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 600;
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, 1);
}

.message {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    margin-top: 1rem;
    color: rgba(107, 114, 128, 1);
}

.info {
    display: flex;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #fff;
}

.right {
    flex: 1;
    margin-left: 24;
}

i {
    padding-right: 10px;
}

#nav-toggle:checked~.main-content {
    margin-left: 70px;
}

#nav-toggle:checked~.main-content header {
    width: calc(100% -70px);
    left: 70px;
}

.main-content {
    transition: margin-left 300ms;
    margin-left: 200px;
}

.main-content header {
    background: #fff;
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    position: fixed;
    left: 200px;
    right: 0;
    /* width: calc(100% -200px); */
    top: 0;
    z-index: 100;
    transition: left 300ms;

}

#nav-toggle {
    display: none;
}

header h3 {
    color: #222;
    margin-right: 50px;
}

header label span {
    font-size: 1.7rem;
    padding-left: 1rem;
}


main {
    margin-top: 85px;
    padding: 2rem 1.5rem;
    background: #f1f5f9;
    min-height: calc(100vh -90px);

}

/* .cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;
    margin-top: 1rem;
} */
.card-single {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    padding: 2rem;
    border-radius: 2px;
}

.card-single div:last-child span {
    color: #1c68c4;
    font-size: 3.5rem;
}

.card-single div:first-child span {
    color: var(--text-grey);

}

.card-single:last-child {
    background: #1c68c4;
}

.card-single:last-child div:last-child span {
    color: #fff;
}

.card-single:last-child div:first-child span {
    color: #fff;
}

.recent-grid {
    margin-top: 3.5rem;

}

.card-header {
    display: flex;
    justify-content: center;
}

.card-body {
    padding: 1rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-header button {
    background: #1c68c4;
    border-radius: 10px;
    color: #fff;
    font-size: 15px;
    padding: .5rem 1rem;
    border: 1px solid #1c68c4;
}

table {
    border-collapse: collapse;
}

thead tr {
    border: 1px solid #1c68c4;
    ;
}

tbody tr {
    border: 1px solid #1c68c4;
    ;
}

thead td {
    font-weight: 700;
}

td {
    padding: .5rem 1rem;
    font-size: 15px;
    color: #222;
    border: 1px solid #1c68c4;
    ;

}

td i {
    color: #1c68c4;
    padding-right: 1rem;
}

.table-responsive {
    width: 100%;
    overflow-x: auto;
}

@media only screen and (max-width:1200px) {
    .sidebar {
        width: 70px;
    }

    .sidebar .sidebar-brand,
    .sidebar li {
        padding-left: 1rem;
        text-align: center;
    }

    .sidebar li a {
        padding-left: 0rem;
    }

    .sidebar li a span {
        padding-right: 1rem;
    }

    .sidebar .sidebar-brand h3 span:last-child,
    .sidebar li a span:last-child {
        display: none;
    }

    .main-content {
        margin-left: 70px;
    }

    .main-content header {
        width: calc(100% -70px);
        left: 70px;
    }

    .sidebar:hover {
        width: 200px;
        z-index: 200;
    }

    .sidebar:hover .sidebar-brand,
    .sidebar:hover li {
        padding-left: 2rem;
        text-align: left;
    }

    .sidebar:hover li a {
        padding-left: 1rem;
    }

    .sidebar:hover li a span {
        padding-right: 1rem;
    }

    .sidebar:hover .sidebar-brand h3 span:last-child,
    .sidebar:hover li a span:last-child {
        display: inline;
    }
}

@media only screen and (max-width: 960px) {
    .cards {
        grid-template-columns: repeat(3, 1fr);
    }

    .recent-grid {
        grid-template-columns: 60% 40%;
    }
}

@media only screen and (max-width: 768px) {
    .cards {
        grid-template-columns: repeat(2, 1fr);
    }

    .recent-grid {
        grid-template-columns: 100%;
    }

    .search-wrapper {
        display: none;
    }

    .sidebar {
        left: -100% !important;
    }

    header h3 {
        display: flex;
        align-items: center;
    }

    header h3 label {
        display: inline-block;
        background: #1c68c4;
        padding-left: 0rem;
        margin-left: 1rem;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center !important;
    }

    header h3 span {
        text-align: center;
        padding-right: 1rem;

    }

    header h3 {
        font-size: 30px;
    }

    .main-content {
        width: 100%;
        margin-left: 0rem;
    }

    header {
        width: 100% !important;
        left: 0 !important;

    }

    #nav-toggle:checked+.sidebar {
        left: 0 !important;
        z-index: 100;
        width: 200px;
    }

    #nav-toggle:checked+.sidebar {
        width: 200px;
        z-index: 200;
    }

    #nav-toggle:checked+.sidebar .sidebar-brand,
    #nav-toggle:checked+.sidebar li {
        padding-left: 2rem;
        text-align: left;
    }

    #nav-toggle:checked+.sidebar li a {
        padding-left: 1rem;
    }

    #nav-toggle:checked+.sidebar li a span {
        padding-right: 1rem;
    }

    #nav-toggle:checked+.sidebar.sidebar-brand h3 span:last-child,
    #nav-toggle:checked+.sidebar li a span:last-child {
        display: inline;
    }

    #nav-toggle:checked~.main-content {
        margin-left: 0rem !important;
    }
}

@media only screen and (max-width: 560px) {
    .cards {
        grid-template-columns: 100%;
    }
}</style>