<template>
    <!-- page wrapper start -->

    <div class="page-wrapper">

        <!-- preloader start -->

        <!-- <div id="ht-preloader">
        <div class="loader clear-loader">
            <div class="loader-box"></div>
            <div class="loader-box"></div>
            <div class="loader-box"></div>
            <div class="loader-box"></div>
            <div class="loader-wrap-text">
                <div class="text">
                    <span>F</span><span>R</span><span>E</span><span>E</span><span>L</span><span>A</span><span>N</span><span>C</span><span>E</span><span>R</span><span>S</span>
                </div>
            </div>
        </div>
    </div> -->
        <!-- preloader end -->
        <logInModal v-if="login" @click="toggleLogin" style="margin-top:100px; position: fixed;" />
        <registerModal v-if="register" @click="toggleRegister"
            style="margin-top:100px; position: fixed; margin-right:100px;" />
        <!--header start-->
        <header id="site-header" class="header header-1">
            <div class="container-fluid">
                <div id="header-wrap" class="box-shadow">
                    <div class="row">

                        <div class="col-lg-12">
                            <!-- Navbar -->
                            <nav class="navbar navbar-expand-lg">
                                <a class="navbar-brand logo" href="/">
                                    <img id="logo-img" class="img-center" src="client/images/logo.png" alt="">
                                </a>
                                <button class="navbar-toggler" type="button" data-toggle="collapse"
                                    data-target="#navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </button>
                                <div class="collapse navbar-collapse" id="navbarNavDropdown">
                                    <!-- Left nav -->
                                    <ul id="main-menu" class="nav navbar-nav ml-auto mr-auto">
                                        <li class="nav-item"> <a class="nav-link hashed active" href="/#">Home</a>
                                        </li>
                                        <li class="nav-item"> <a class="nav-link hashed" href="/#about">What we offer
                                            </a>
                                        </li>
                                        <li class="nav-item"> <a class="nav-link hashed" href="/#service">Our Portfolio</a>
                                        </li>
                                        <li class="nav-item"> <a class="nav-link hashed" href="/#step">How To Get Started
                                            </a>
                                        </li>
                                        <!-- <li class="nav-item"> <a class="nav-link" href="#reviews">Reviews</a>
                                        </li> -->
                                        <li class="nav-item" v-if="mobileLogin"><a href="mailto:info@qualityasoftwares.com"
                                                style="color: #303030!important" class="nav-link hashed">Email Us <i
                                                    class="fa-solid fa-envelope"></i></a>
                                        </li>
                                        <li class="nav-item" v-if="mobileLogin">
                                            <a href="https://api.whatsapp.com/send?phone=+254795755494"
                                                style="color: green!important" class="nav-link hashed">Whatsapp Us <i
                                                    class="fa-brands fa-square-whatsapp"></i> </a>
                                        </li>
                                        <li v-if="mobileLogin" class="nav-item"> <a class="nav-link hashed"
                                                @click="toggleLogin()">Login</a>
                                        </li>
                                        <li v-if="mobileLogin" class="nav-item"> <a class="nav-link hashed"
                                                @click="toggleRegister()">Register</a>
                                        </li>
                                        <!-- <li v-if="mobileLogin && user" class="nav-item"> <a class="nav-link hashed"
                                                @click="Logout()">Log Out</a>
                                        </li> -->
                                    </ul>
                                </div>

                                <a href="mailto:info@qualityasoftwares.com" class="email btn-sm"><i
                                        class="fa-solid fa-envelope"></i></a>
                                <!-- <i class="fa-sharp fa-solid fa-bell-slash"></i> -->


                                <a href="https://api.whatsapp.com/send?phone=+254795755494" class="whatsapp btn-sm"><i
                                        class="fa-brands fa-square-whatsapp"></i> </a>

                                <a class="btn btn-theme btn-sm" @click="toggleRegister()" data-text="Register"
                                    style="color: #fff">
                                    <span>R</span><span>e</span><span>g</span><span>i</span><span>s</span><span>t</span><span>e</span><span>r</span>
                                </a>
                                <a class="btn btn-theme btn-sm" @click="toggleLogin()" data-text="Login"
                                    style="color: #fff">
                                    <span>L</span><span>o</span><span>g</span><span>i</span><span>n</span>
                                </a>
                                <!-- <a v-if="user" class="btn btn-theme btn-sm" @click="Logout()" data-text="Log Out" style="color: #fff">
                                    <span>L</span><span>o</span><span>g</span><span></span><span>O</span><span>u</span><span>t</span>
                                </a> -->
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <!--header end-->


        <!--body content start-->

        <div class="page-content">
            <div class="app-content content" v-if="admin">
                <div class="content-wrapper">
                    <div class="content-body">

                        <noscript>
                            <div class="alert alert-icon-left alert-danger alert-dismissible mb-2" id="no-script"
                                role="alert">
                                <span class="alert-icon"><i class="fa fa-info"></i></span>
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </noscript>
                        <section class="flexbox-container table-responsive py-5">
                            <div class="col-12 d-flex align-items-center justify-content-center">
                                <div class="col-md-5 box-shadow-2 p-0 plain-container">
                                    <div class="border-grey border-lighten-3 m-0">
                                        <div class="card-header border-0">

                                            <h6 class="card-subtitle line-on-side text-muted text-center font-small-3 pt-2">
                                                <span>Post Portfolio</span>
                                            </h6>
                                        </div>
                                        <div class="card-content">
                                            <div class="card-body">
                                                <div class="alert alert-danger" v-if="error">
                                                    {{ this.errorMsg }}
                                                </div>
                                                <form class="form-horizontal form-simple" @submit.prevent="handleSubmit()">
                                                    <TheLoader v-if="loading" />
                                                    <input type="hidden" name="_token">
                                                    <div class="row">
                                                        <div class="col-sm-6 pd-right">
                                                            <div class="form-group">
                                                                <input type="text" name="title" class="form-control"
                                                                    placeholder="Title" required="" v-model.trim="title">
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6 pd-left">
                                                            <div class="form-group">
                                                                <input type="text" class="form-control" placeholder="Link"
                                                                    required="" v-model.trim="link">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 pd-left">
                                                            <div class="form-group">
                                                                <label for="avatar">Description</label>
                                                                <textarea name="description" class="form-control"
                                                                    v-model.trim="description">
                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 pd-left">
                                                            <div class="form-group">
                                                                <label for="avatar">Cover Image</label>
                                                                <input type="file" name="avatar" ref="imageFile"
                                                                    id="cover-file" @change="fileChange"
                                                                    class="form-control">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <button type="submit" class="btn blue-bg btn-lg btn-block">
                                                        Submit
                                                    </button>
                                                </form>
                                                <input type="file" style="display:none;">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            <!--feature start-->
            <div class="title-effect">
                <div class="bar bar-top"></div>
                <div class="bar bar-right"></div>
                <div class="bar bar-bottom"></div>
                <div class="bar bar-left"></div>
            </div>
            <div style="text-align: center;">
                <h2>Our Portfolio</h2>
                <h6>Experience Our High-Quality Work</h6>
            </div>
            <section id="service" class="service pos-r bg-effect o-hidden">
                <div class="card-container">

                    <div class="card" v-for="(website, index) in portfolio" :key="website.id">
                        <div class="featured-item style-3">
                            <div class="featured-icon">
                                <img class="img-center wow jackInTheBox" :src="website.imageCoverFile" alt="">
                            </div>
                            <div class="featured-title">
                                <h5>{{ website.title }}</h5>
                            </div>
                            <div class="featured-desc">
                                <p>{{ website.description }}</p>
                            </div>
                            <a class="btn btn-theme btn-circle" :href="website.link" data-text="Visit Site">
                                <span>V</span><span>i</span><span>s</span><span>i</span><span>t</span>
                                <span></span><span>S</span><span>i</span><span>t</span><span>e</span>
                            </a>
                        </div>
                    </div>


                </div>
            </section>
        </div>
        <!--feature end-->


        <!--footer start-->

        <footer class="footer footer-1 pos-r" data-bg-img="images/bg/05.png') ">
            <div class="subscribe-box">
                <div class="container">
                    <div class="row subscribe-inner align-items-center">
                        <div class="col-md-6 col-sm-12">
                            <h4>Subscribe To Our Newsletter</h4>
                            <p class="lead mb-0">Get latest news and feature updates from Quality-A.</p>
                        </div>
                        <div class="col-md-6 col-sm-12 sm-mt-2">
                            <div class="subscribe-form sm-mt-2">
                                <form id="mc-form" class="group" @submit.prevent="handleSubscribe()">
                                    <input type="email" v-model="email" name="EMAIL" placeholder="Email Address"
                                        required="">
                                    <input class="btn btn-theme" type="submit" value="Subscribe">
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="primary-footer">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="footer-logo">
                                <img id="footer-logo-img" src="client/images/logo.png" class="img-center" alt="">
                            </div>
                            <p class="mb-0"><router-link to="/" style="color: #2575fc">Quality-A</router-link> a dynamic
                                software company dedicated to delivering tailored digital solutions, from customized
                                websites and management systems to proprietary software products, with a commitment to
                                excellence and innovation.</p>
                        </div>
                        <div class="col-lg-4 col-md-6 pl-md-5 sm-mt-5 footer-list justify-content-between d-flex">
                            <ul class="list-unstyled w-100">
                                <li> <a href="#home">Home</a>
                                </li>
                                <li> <a href="#about">What we offer</a>
                                </li>
                                <li> <a href="#service">Our portfolio</a>
                                </li>
                                <li> <a data-toggle="modal" data-target="#termsModal">Terms and Policy</a>
                                </li>

                            </ul>
                            <ul class="list-unstyled w-100">
                                <li @click="toggleLogin()">Login
                                </li>
                                <li @click="toggleRegister()">Register
                                </li>
                                <li> <a href="#step">How It Works</a>
                                </li>

                            </ul>
                        </div>

                        <div class="col-lg-4 col-md-12 md-mt-5">
                            <ul class="media-icon list-unstyled">
                                <li>Email: <a href="mailto:info@qualityasoftwares.com"><b>info@qualityasoftwares.com</b></a>
                                </li>
                                <li>Phone: <a href="tel:+254795755494"><b>+254 795755494</b></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="secondary-footer">
                <div class="container">
                    <div class="copyright">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12"> <span>Copyright &copy; {{ currentYear }}<u> <a href="#">
                                            Quality-A</a>.</u> |
                                    All Rights Reserved</span>
                            </div>
                            <div class="col-lg-6 col-md-12 text-lg-right md-mt-3">
                                <div class="footer-social">
                                    <ul class="list-inline">
                                        <li class="mr-2"><a target="_blank" href="https://web.facebook.com/qualityasoftwares" style="color: #2575fc"><i
                                                    class="fab fa-facebook-f"></i> Facebook</a>
                                        </li>
                                        <li class="mr-2"><a target="_blank" href="https://twitter.com/qualitya_"><i class="fa-brands fa-x-twitter"></i>
                                                X-twitter</a>
                                        </li>
                                        <li class="mr-2"><a target="_blank" href="https://www.linkedin.com/in/quality-a-softwares-1025052b4" style="color: #2575fc"><i class="fa-brands fa-linkedin"></i>
                                                LinkedIn</a>
                                        </li>
                                        <li class="mr-2"><a target="_blank" href="https://vm.tiktok.com/ZM6wQQSMe/"><i class="fa-brands fa-tiktok"></i>
                                                TikToK</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

        <!--footer end-->

        <!-- Register Modal -->

        <!-- Login Modal -->


        <div class="modal fade" id="termsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div style="display: flex;">
                        <div style="width: 100%">
                            <h5 style="text-align: center; padding:10px" class="modal-title" id="exampleModalLabel">
                                Terms and Policy
                            </h5>
                        </div>
                        <div style="padding-top:10px; padding-right:10px"><i data-dismiss="modal" aria-label="Close"
                                class="fa fa-times"></i>
                        </div>
                    </div>

                    <div class="modal-body">
                        <pre
                            style="text-align: center; font-family: Roboto, sans-serif; white-space: pre-wrap; font-size:15px">
  1. All orders done under the private domain of clients and developers are solely their responsibility and doesn&#039;t reflect any activity that involves Quality-A</pre>
                        <p>2. 50 % downpayment on all orders</p>
                        <p>3. Pay only through our official webisite or Our official accounts </p>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <!-- page wrapper end -->

    <!--back-to-top start-->



    <!--back-to-top end-->


    <!-- inject js start -->
</template>
  
<script>
// @ is an alias to /src
import TheLoader from "@/components/TheLoader";
import logInModal from "@/components/core/logInModal.vue";
import registerModal from "@/components/core/registerModal.vue";

import { getStorage, ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { collection, setDoc, doc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { mapState, mapActions } from 'vuex';

export default {
    name: 'HpmeView',
    components: {
        logInModal,
        registerModal,
        TheLoader
    },
    data() {
        return {
            login: null,
            register: null,
            windowWidth: null,
            mobileLogin: null,
            currentYear: new Date().getFullYear(),
            loading: null,
            title: null,
            link: null,
            description: null,
        }
    },
    computed: {
        admin() {
            return this.$store.state.profileAdmin;
        },
        user() {
            return this.$store.state.user;
        },
        orderFileName() {
            return this.$store.state.orderFileName;
        },
        ...mapState(['portfolio'])
    },
    methods: {
        toggleLogin() {
            this.login = !this.login
        },
        toggleRegister() {
            this.register = !this.register
        },
        checkScreen() {
            this.windowWidth = window.innerWidth;
            if (this.windowWidth <= 750) {
                this.mobileLogin = true;
                return;
            }
            this.mobileLogin = false;
            return;
        },

        async handleSubscribe() {

            const db = getFirestore();
            const timestamp = Date.now();

            const subscriptionRef = collection(db, "subscriptions");
            const newSubscriptionRef = doc(subscriptionRef);

            await setDoc(newSubscriptionRef, {
                id: timestamp,
                email: email
            });

            alert("Subscription Success ...")
        },
        fileChange() {
            if (this.$refs.imageFile && this.$refs.imageFile.files.length > 0) {
                this.file = this.$refs.imageFile.files[0];
                const fileName = this.file.name;
                this.$store.commit("orderFileNameChange", fileName);
                this.$store.commit("createOrderFileURL", URL.createObjectURL(this.file));
            } else {
                console.log("no file");
            }
        },
        async handleSubmit() {
            if (this.title.length !== 0 && this.link.length !== 0) {
                this.loading = true;
                if (this.file) {

                    this.loading = true;
                    const storage = getStorage();
                    const storageRef = ref(
                        storage,
                        `documents/portfolioImageFiles/${this.$store.state.orderFileName}`
                    );
                    const uploadTask = uploadBytesResumable(storageRef, this.file);
                    uploadTask.on(
                        "state_changed",
                        (snapshot) => {
                            console.log(snapshot);
                        },
                        (err) => {
                            console.log(err);
                            this.loading = false;
                        },
                        async () => {
                            const downloadURL = await getDownloadURL(storageRef);
                            const db = getFirestore();
                            const timestamp = await Date.now();


                            const portfolioCollectionRef = collection(db, "portfolio");
                            const portfolioRef = doc(portfolioCollectionRef);
                            await setDoc(portfolioRef, {
                                imageCoverFile: downloadURL,
                                imageCoverFileName: this.$store.state.orderFileName,
                                title: this.title,
                                link: this.link,
                                description: this.description,
                                id: timestamp

                            });
                            this.loading = false;
                            this.$router.push("/portfolio");
                            alert("Portfolio Updated successfully")
                        }
                    );
                    return;
                }
                else {

                    this.error = true;
                    this.errorMsg = "Please Upload File";
                    return;
                }

            }
            this.error = true;
            this.errorMsg = "Please ensure all fields necessary have been filled!";
            setTimeout(() => {
                this.error = false;
            }, 5000);
            return;
        },
        ...mapActions(['getPortFolio']),
        Logout() {
            getAuth().signOut();
            // window.location.reload();
            this.$router.replace('/');
        },
    },
    created() {
        window.addEventListener("resize", this.checkSreen);
        this.checkScreen();
        this.getPortFolio();
    },

}
</script>
<style scoped>
.whatsapp {
    margin: 10px;
    font-size: 35px;
    color: green !important;
}

.email {
    margin: 10px;
    font-size: 35px;
    color: #303030 !important;
}

ul {
    margin: 25px;
}

.page-content {
    padding-top: 150px;
}

.card {
    width: 23%;
    margin-bottom: 10px;
}

.service {
    margin-bottom: 0;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 10%;
    margin-right: 10%;
}

@media (max-width: 800px) {
    .email,
    .whatsapp{
        display: none;
    }
    ul {
        width: 100%;
    }

    .card-container,
    .card {
        width: 100%;
        margin: 5px;
    }
}
</style>
  
  
  