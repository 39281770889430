<template>
    <!-- page wrapper start -->

    <div class="page-wrapper">

        <!-- preloader start -->

        <!-- <div id="ht-preloader">
        <div class="loader clear-loader">
            <div class="loader-box"></div>
            <div class="loader-box"></div>
            <div class="loader-box"></div>
            <div class="loader-box"></div>
            <div class="loader-wrap-text">
                <div class="text">
                    <span>F</span><span>R</span><span>E</span><span>E</span><span>L</span><span>A</span><span>N</span><span>C</span><span>E</span><span>R</span><span>S</span>
                </div>
            </div>
        </div>
    </div> -->
        <!-- preloader end -->
        <logInModal v-if="login" @click="toggleLogin" style="margin-top:100px; position: fixed;" />
        <registerModal v-if="register" @click="toggleRegister"
            style="margin-top:100px; position: fixed; margin-right:100px;" />
        <!--header start-->
        <header id="site-header" class="header header-1">
            <div class="container-fluid">
                <div id="header-wrap" class="box-shadow">
                    <div class="row">

                        <div class="col-lg-12">
                            <!-- Navbar -->
                            <nav class="navbar navbar-expand-lg">
                                <a class="navbar-brand logo" href="/">
                                    <img id="logo-img" class="img-center" src="client/images/logo.png" alt="">
                                </a>
                                <button class="navbar-toggler" type="button" data-toggle="collapse"
                                    data-target="#navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </button>
                                <div class="collapse navbar-collapse" id="navbarNavDropdown">
                                    <!-- Left nav -->
                                    <ul id="main-menu" class="nav navbar-nav ml-auto mr-auto">
                                        <li class="nav-item"> <a class="nav-link hashed active" href="#">Home</a>
                                        </li>
                                        <li class="nav-item"> <a class="nav-link hashed" href="#about">What we offer
                                            </a>
                                        </li>
                                        <li class="nav-item"> <a class="nav-link hashed" href="#service">Our Portfolio</a>
                                        </li>
                                        <li class="nav-item"> <a class="nav-link hashed" href="#step">How To Get Started
                                            </a>
                                        </li>
                                        <!-- <li class="nav-item"> <a class="nav-link" href="#reviews">Reviews</a>
                                        </li> -->
                                        <li class="nav-item" v-if="mobileLogin"><a href="mailto:info@qualityasoftwares.com"
                                                style="color: #303030!important" class="nav-link hashed">Email Us <i
                                                    class="fa-solid fa-envelope"></i></a>
                                        </li>
                                        <li class="nav-item" v-if="mobileLogin">
                                            <a href="https://api.whatsapp.com/send?phone=+254795755494"
                                                style="color: green!important" class="nav-link hashed">Whatsapp Us <i
                                                    class="fa-brands fa-square-whatsapp"></i> </a>
                                        </li>
                                        <li v-if="mobileLogin" class="nav-item"> <a class="nav-link hashed"
                                                @click="toggleLogin()">Login</a>
                                        </li>
                                        <li v-if="mobileLogin" class="nav-item"> <a class="nav-link hashed"
                                                @click="toggleRegister()">Register</a>
                                        </li>
                                        <!-- <li v-if="mobileLogin && user" class="nav-item"> <a class="nav-link hashed"
                                                @click="Logout()">Log Out</a>
                                        </li> -->
                                    </ul>
                                </div>

                                <a href="mailto:info@qualityasoftwares.com" class="email btn-sm"><i
                                        class="fa-solid fa-envelope"></i></a>
                                <!-- <i class="fa-sharp fa-solid fa-bell-slash"></i> -->


                                <a href="https://api.whatsapp.com/send?phone=+254795755494" class="whatsapp btn-sm"><i
                                        class="fa-brands fa-square-whatsapp"></i> </a>

                                <a class="btn btn-theme btn-sm" @click="toggleRegister()" data-text="Register"
                                    style="color: #fff">
                                    <span>R</span><span>e</span><span>g</span><span>i</span><span>s</span><span>t</span><span>e</span><span>r</span>
                                </a>
                                <a class="btn btn-theme btn-sm" @click="toggleLogin()" data-text="Login"
                                    style="color: #fff">
                                    <span>L</span><span>o</span><span>g</span><span>i</span><span>n</span>
                                </a>
                                <!-- <a v-if="user" class="btn btn-theme btn-sm" @click="Logout()" data-text="Log Out" style="color: #fff">
                                    <span>L</span><span>o</span><span>g</span><span></span><span>O</span><span>u</span><span>t</span>
                                </a> -->
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <!--header end-->

        <section id="home" class="fullscreen-banner banner p-0 bg-contain bg-pos-r" data-bg-img="images/bg/01.png') }}">
            <div class="spinner-eff">
                <div class="spinner-circle circle-1"></div>
                <div class="spinner-circle circle-2"></div>
            </div>
            <div class="align-center pt-0">

                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 order-lg-1">
                            <img class="img-center wow jackInTheBox" data-wow-duration="3s"
                                src="@/assets/client/images/banner/01.png" alt="">
                        </div>
                        <div class="col-lg-6 col-md-12 order-lg-1 md-mt-5">
                            <h1 class="mb-4 wow fadeInUp" data-wow-duration="1.5s"><a href="javascript:void(0)"
                                    style="color: rgb(11, 52, 124)">welcome</a> To Quality-A</h1>
                            <p class="lead mb-4 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                                Quality-A is a premier software company, crafting custom websites, management systems, and
                                running proprietary software products. Committed to excellence, we deliver tailored
                                solutions to empower clients in the digital realm, ensuring top-notch performance and user
                                satisfaction.
                            </p>
                            <h5>why you should choose us</h5>
                            <ol>
                                <li>Quality Services</li>
                                <li>Ontime task completion</li>
                                <li>Affordable Services</li>
                            </ol>


                            <a class="btn btn-dark wow fadeInUp" data-wow-duration="3s" data-wow-delay="0.5s" href="#"
                                data-text="Learn More">
                                <span>L</span><span>e</span><span>a</span><span>r</span><span>n</span>
                                <span> </span><span>M</span><span>o</span><span>r</span><span>e</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!--hero section end-->


        <!--body content start-->

        <div class="page-content">
            <!--about start-->

            <section id="about" class="pos-r bg-contain bg-pos-l py-10" data-bg-img="images/bg/02.png') ">
                <div class="container">
                    <div class="section-title mb-4">
                        <h2>Our Services</h2>
                    </div>
                    <!-- <div class="row align-items-center">
                <div class="col-lg-7 col-md-12 image-column p-0 pr-lg-5"> -->
                    <div style="display: flex; flex-direction: row; margin:10px 0; flex-wrap: wrap;">
                        <ul class="list-unstyled list-icon mb-4">
                            <h4>Website Development:</h4>
                            <li style="color: #0062ff;">Corporate Websites:</li>
                            <li style="margin-left: 30px"><i class="la la-check"></i><i class="la la-check"></i>Business
                                Portfolios</li>
                            <li style="margin-left: 30px"><i class="la la-check"></i><i class="la la-check"></i>Company
                                Profiles</li>
                            <li style="color: #0062ff;">E-commerce Websites:</li>
                            <li style="margin-left: 30px"><i class="la la-check"></i>Online Stores</li>
                            <li style="margin-left: 30px"><i class="la la-check"></i>Product Catalogs</li>
                            <li style="color: #0062ff;">Landing Pages:</li>
                            <li style="margin-left: 30px"><i class="la la-check"></i>Promotional Pages</li>
                            <li style="margin-left: 30px"><i class="la la-check"></i>Campaign-Specific Pages</li>
                            <li style="color: #0062ff;">Blogs and Content Websites:</li>
                            <li style="margin-left: 30px"><i class="la la-check"></i>Personal Blogs</li>
                            <li style="margin-left: 30px"><i class="la la-check"></i>Content Publishing Platforms</li>

                            <li style="color: #0062ff;">Educational Websites:</li>
                            <li style="margin-left: 30px"><i class="la la-check"></i>Online Courses Platforms</li>
                            <li style="margin-left: 30px"><i class="la la-check"></i>Educational Resource Portals</li>
                            <li style="color: #0062ff;">Portfolio Websites:</li>
                            <li style="margin-left: 30px"><i class="la la-check"></i>Artist Portfolios</li>
                            <li style="margin-left: 30px"><i class="la la-check"></i>Creative Professional Showcases</li>
                            <li style="color: #0062ff;">Non-Profit and NGO Websites:</li>
                            <li style="margin-left: 30px"><i class="la la-check"></i>Donation Platforms</li>
                            <li style="margin-left: 30px"><i class="la la-check"></i>Social Impact Portals</li>
                            <li style="color: #0062ff;">Event Websites:</li>
                            <li style="margin-left: 30px"><i class="la la-check"></i>Conference Websites</li>
                            <li style="margin-left: 30px"><i class="la la-check"></i>Event Registration Platforms</li>

                        </ul>
                        <ul class="list-unstyled list-icon mb-4">
                            <h4>Management Systems Development:</h4>
                            <li style="color: #0062ff;">School Management Systems:</li>
                            <li style="margin-left: 30px"><i class="la la-check"></i>Student Information Systems</li>
                            <li style="margin-left: 30px"><i class="la la-check"></i>Grade and Attendance Tracking</li>
                            <li style="color: #0062ff;">Human Resource Management Systems (HRMS):</li>
                            <li style="margin-left: 30px"><i class="la la-check"></i>Employee Database</li>
                            <li style="margin-left: 30px"><i class="la la-check"></i>Leave and Attendance Management</li>
                            <li style="color: #0062ff;">Customer Relationship Management (CRM):</li>
                            <li style="margin-left: 30px"><i class="la la-check"></i>Lead and Sales Tracking</li>
                            <li style="margin-left: 30px"><i class="la la-check"></i>Customer Communication Tools</li>

                            <li style="color: #0062ff;">Inventory Management Systems:</li>
                            <li style="margin-left: 30px"><i class="la la-check"></i>Stock Tracking</li>
                            <li style="margin-left: 30px"><i class="la la-check"></i>Order Processing</li>

                            <li style="color: #0062ff;">Project Management Systems:</li>
                            <li style="margin-left: 30px"><i class="la la-check"></i>Task Assignment and Tracking</li>
                            <li style="margin-left: 30px"><i class="la la-check"></i>Collaboration Platforms</li>
                            <li style="color: #0062ff;">Hospital Management Systems:</li>
                            <li style="margin-left: 30px"><i class="la la-check"></i>Patient Records</li>
                            <li style="margin-left: 30px"><i class="la la-check"></i>Appointment Scheduling</li>
                            <li style="color: #0062ff;">Financial Management Systems:</li>
                            <li style="margin-left: 30px"><i class="la la-check"></i>Accounting Software</li>
                            <li style="margin-left: 30px"><i class="la la-check"></i>Budgeting and Expense Tracking</li>

                            <li style="color: #0062ff;">Library Management Systems:</li>
                            <li style="margin-left: 30px"><i class="la la-check"></i>Cataloging and Book Tracking</li>
                            <li style="margin-left: 30px"><i class="la la-check"></i>Membership Management</li>
                           
                            <a class="btn btn-border btn-circle" href="#" data-text="Sign Up Now" @click="toggleRegister()">
                                <span>S</span><span>i</span><span>g</span><span>n</span><span>u</span><span>p</span>
                                <span> </span><span>N</span><span>o</span><span>w</span>
                            </a>
                        </ul>

                    </div>
                </div>
            </section>

            <!--about end-->


            <!--feature start-->
            <div class="title-effect">
                <div class="bar bar-top"></div>
                <div class="bar bar-right"></div>
                <div class="bar bar-bottom"></div>
                <div class="bar bar-left"></div>
            </div>
            <div style="text-align: center;">
                <h2>Our Portfolio</h2>
                <h6>Experience Our High-Quality Work</h6>
            </div>
            <section id="service" class="service pos-r bg-effect o-hidden">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 md-mt-5 order-lg-1">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="featured-item style-3">
                                        <div class="featured-icon">
                                            <img class="img-center wow jackInTheBox" data-wow-duration="3s"
                                                src="images/kunakazi.png" alt="">
                                        </div>
                                        <div class="featured-title">
                                            <h5>Kunakazi | Jobs. Profiles. Events</h5>
                                        </div>
                                        <div class="featured-desc">
                                            <p>A hub for casual employee profiles, job listings, and events.</p>
                                        </div>
                                        <a class="btn btn-theme btn-circle" href="https://kunakazi.co.ke"
                                            data-text="Visit Site">
                                            <span>V</span><span>i</span><span>s</span><span>i</span><span>t</span>
                                            <span>
                                            </span><span>S</span><span>i</span><span>t</span><span>e</span>
                                        </a>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="featured-item style-3">
                                        <div class="featured-icon">
                                            <img class="img-center wow jackInTheBox" data-wow-duration="3s"
                                                src="images/coolscreenshort.png" alt="">
                                        </div>
                                        <div class="featured-title">
                                            <h5>Cool Video Player</h5>
                                        </div>
                                        <div class="featured-desc">
                                            <p>A video streaming platform with a sleek interface for seamless playlist
                                                management.</p>
                                        </div>
                                        <a class="btn btn-theme btn-circle" href="https://cool-video-player.vercel.app"
                                            data-text="Visit Site">
                                            <span>V</span><span>i</span><span>s</span><span>i</span><span>t</span>
                                            <span>
                                            </span><span>S</span><span>i</span><span>t</span><span>e</span>
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 md-mt-5 order-lg-1">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="featured-item style-3">
                                        <div class="featured-icon">
                                            <img class="img-center wow jackInTheBox" data-wow-duration="3s"
                                                src="images/portfolio.png" alt="">
                                        </div>
                                        <div class="featured-title">
                                            <h5>Personal Portfolio</h5>
                                        </div>
                                        <div class="featured-desc">
                                            <p>A well Designed portfolio showcasing experiences, skills and projects.</p>
                                        </div>
                                        <a class="btn btn-theme btn-circle" href="https://musunzafestus.vercel.app"
                                            data-text="Visit Site">
                                            <span>V</span><span>i</span><span>s</span><span>i</span><span>t</span>
                                            <span>
                                            </span><span>S</span><span>i</span><span>t</span><span>e</span>
                                        </a>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="featured-item style-3">
                                        <div class="featured-icon">
                                            <i class="fa fa-book"></i>
                                        </div>
                                        <div class="featured-title">
                                            <h5></h5>
                                        </div>
                                        <div class="featured-desc">
                                            <p>Explore additional websites and management systems on the following page
                                            </p>
                                            <router-link class="btn btn-theme btn-circle" to="/portfolio"
                                                data-text="See More">
                                                <span>s</span><span>e</span><span>e</span>
                                                <span>
                                                </span><span>M</span><span>o</span><span>r</span><span>e</span>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!--feature end-->


            <!--step start-->
            <section id="step" class="text-center pos-r">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-md-10 ml-auto mr-auto">
                            <div class="section-title">
                                <div class="title-effect">
                                    <div class="bar bar-top"></div>
                                    <div class="bar bar-right"></div>
                                    <div class="bar bar-bottom"></div>
                                    <div class="bar bar-left"></div>
                                </div>
                                <h6>How It Works</h6>
                                <h2 class="title">An Easy Step-By-Step Process.</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div id="svg-container">
                            <svg id="svgC" width="100%" height="100%" viewBox="0 0 620 120"
                                preserveAspectRatio="xMidYMid meet"></svg>
                        </div>
                        <div class="col-lg-3 col-md-12">
                            <div class="work-process">
                                <div class="box-loader"> <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                                <div class="step-num-box">
                                    <div class="step-icon"><span><i class="fa fa-user"></i></span>
                                    </div>
                                    <div class="step-num">01</div>
                                </div>
                                <div class="step-desc">
                                    <h4>Register an account with us</h4>
                                    <p class="mb-0">Create an account with us to access your own personalised
                                        dashboard.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-12 md-mt-5">
                            <div class="work-process">
                                <div class="step-num-box">
                                    <div class="step-icon"><span><i class="fa fa-upload"></i></span>
                                    </div>
                                    <div class="step-num">02</div>
                                </div>
                                <div class="step-desc">
                                    <h4>Post Orders to the System</h4>
                                    <p class="mb-0">wait for a payment message to inform you what you should pay. you can
                                        still negotiate on the price from the chat section</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-12 md-mt-5">
                            <div class="work-process">
                                <div class="box-loader"> <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                                <div class="step-num-box">
                                    <div class="step-icon"><span><i class="fa fa-share"></i></span>
                                    </div>
                                    <div class="step-num">03</div>
                                </div>
                                <div class="step-desc">
                                    <h4>Payment</h4>
                                    <p class="mb-0">1. 50 % downpayment on all orders.</p>
                                    <p class="mb-0">2. Payments done through our offcial website or via our official
                                        accounts only.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-12 md-mt-5">
                            <div class="work-process">
                                <div class="step-num-box">
                                    <div class="step-icon"><span><i class="fa fa-file"></i></span>
                                    </div>
                                    <div class="step-num">04</div>
                                </div>
                                <div class="step-desc">
                                    <h4>Your order will be delivered to you after completion</h4>
                                    <p class="mb-0">This is done in the due time
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <!--step end-->
            <!--review start-->

            <!-- <section id="reviews" class="service pos-r bg-effect o-hidden">
                <div style="text-align: center;">
                    <h2>Reviews</h2>
                </div>
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12 md-mt-5 order-lg-1">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="featured-item style-3">
                                        <div class="featured-icon" style="display: flex; margin-bottom: 10px;">
                                            <img src="images/BuyoneFree_65.jpg" width="30px" alt="">
                                            <h6 style="margin-left: 10px;">Daniel</h6>
                                        </div>
                                        <div class="featured-desc">
                                            <p>Fantastic work. They built my website</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="featured-item style-3">
                                        <div class="featured-icon" style="display: flex; margin-bottom: 10px;">
                                            <img src="images/BuyoneFree_65.jpg" width="30px" alt="">
                                            <h6 style="margin-left: 10px;">Daniel</h6>
                                        </div>
                                        <div class="featured-desc">
                                            <p>Fantastic work. They built my website</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 md-mt-5 order-lg-1">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="featured-item style-3">
                                        <div class="featured-icon" style="display: flex; margin-bottom: 10px;">
                                            <img src="images/BuyoneFree_65.jpg" width="30px" alt="">
                                            <h6 style="margin-left: 10px;">Daniel</h6>
                                        </div>
                                        <div class="featured-desc">
                                            <p>Fantastic work. They built my website</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="featured-item style-3">
                                        <div class="featured-icon" style="display: flex; margin-bottom: 10px;">
                                            <img src="images/BuyoneFree_65.jpg" width="30px" alt="">
                                            <h6 style="margin-left: 10px;">Daniel</h6>
                                        </div>
                                        <div class="featured-desc">
                                            <p>Fantastic work. They built my website</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
  
                    </div>
                    <router-link class="btn btn-dark wow fadeInUp" data-wow-duration="3s" data-wow-delay="0.5s"
                        to="/client/reviews" data-text="See More"> <span>S</span><span>e</span><span>e</span>
                        <span> </span><span>M</span><span>o</span><span>r</span><span>e</span>
                    </router-link>
                </div>
  
            </section> -->

        </div>

        <!--body content end-->
        <!--footer start-->

        <footer class="footer footer-1 pos-r" data-bg-img="images/bg/05.png') ">
            <div class="subscribe-box">
                <div class="container">
                    <div class="row subscribe-inner align-items-center">
                        <div class="col-md-6 col-sm-12">
                            <h4>Subscribe To Our Newsletter</h4>
                            <p class="lead mb-0">Get latest news and feature updates from Quality-A.</p>
                        </div>
                        <div class="col-md-6 col-sm-12 sm-mt-2">
                            <div class="subscribe-form sm-mt-2">
                                <form id="mc-form" class="group" @submit.prevent="handleSubscribe()">
                                    <input type="email" v-model="email" placeholder="Email Address" required="">
                                    <input class="btn btn-theme" type="submit" value="Subscribe">
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="primary-footer">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="footer-logo">
                                <img id="footer-logo-img" src="client/images/logo.png" class="img-center" alt="">
                            </div>
                            <p class="mb-0"><router-link to="/" style="color: #2575fc">Quality-A</router-link> a dynamic
                                software company dedicated to delivering tailored digital solutions, from customized
                                websites and management systems to proprietary software products, with a commitment to
                                excellence and innovation.</p>
                        </div>
                        <div class="col-lg-4 col-md-6 pl-md-5 sm-mt-5 footer-list justify-content-between d-flex">
                            <ul class="list-unstyled w-100">
                                <li> <a href="#home">Home</a>
                                </li>
                                <li> <a href="#about">What we offer</a>
                                </li>
                                <li> <a href="#service">Our portfolio</a>
                                </li>
                                <li> <a data-toggle="modal" data-target="#termsModal">Terms and Policy</a>
                                </li>

                            </ul>
                            <ul class="list-unstyled w-100">
                                <li @click="toggleLogin()">Login
                                </li>
                                <li @click="toggleRegister()">Register
                                </li>
                                <li> <a href="#step">How It Works</a>
                                </li>

                            </ul>
                        </div>

                        <div class="col-lg-4 col-md-12 md-mt-5">
                            <ul class="media-icon list-unstyled">
                                <li>Email: <a href="mailto:info@qualityasoftwares.com"><b>info@qualityasoftwares.com</b></a>
                                </li>
                                <li>Phone: <a href="tel:+254795755494"><b>+254 795755494</b></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="secondary-footer">
                <div class="container">
                    <div class="copyright">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12"> <span>Copyright &copy; {{ currentYear }}<u> <a href="#">
                                            Quality-A</a>.</u> |
                                    All Rights Reserved</span>
                            </div>
                            <div class="col-lg-6 col-md-12 text-lg-right md-mt-3">
                                <div class="footer-social">
                                    <ul class="list-inline">
                                        <li class="mr-2"><a target="_blank"
                                                href="https://web.facebook.com/qualityasoftwares" style="color: #2575fc"><i
                                                    class="fab fa-facebook-f"></i> Facebook</a>
                                        </li>
                                        <li class="mr-2"><a target="_blank" href="https://twitter.com/qualitya_"><i
                                                    class="fa-brands fa-x-twitter"></i>
                                                X-twitter</a>
                                        </li>
                                        <li class="mr-2"><a target="_blank"
                                                href="https://www.linkedin.com/in/quality-a-softwares-1025052b4"
                                                style="color: #2575fc"><i class="fa-brands fa-linkedin"></i>
                                                LinkedIn</a>
                                        </li>
                                        <li class="mr-2"><a target="_blank" href="https://vm.tiktok.com/ZM6wQQSMe/"><i
                                                    class="fa-brands fa-tiktok"></i>
                                                TikToK</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

        <!--footer end-->

        <!-- Register Modal -->

        <!-- Login Modal -->


        <div class="modal fade" id="termsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div style="display: flex;">
                        <div style="width: 100%">
                            <h5 style="text-align: center; padding:10px" class="modal-title" id="exampleModalLabel">
                                Terms and Policy
                            </h5>
                        </div>
                        <div style="padding-top:10px; padding-right:10px"><i data-dismiss="modal" aria-label="Close"
                                class="fa fa-times"></i>
                        </div>
                    </div>

                    <div class="modal-body">
                        <pre
                            style="text-align: center; font-family: Roboto, sans-serif; white-space: pre-wrap; font-size:15px">
  1. All orders done under the private domain of clients and developers are solely their responsibility and doesn&#039;t reflect any activity that involves Quality-A</pre>
                        <p>2. 50 % downpayment on all orders</p>
                        <p>3. Pay only through official accounts in the Order details page</p>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <!-- page wrapper end -->

    <!--back-to-top start-->



    <!--back-to-top end-->


    <!-- inject js start -->
</template>
  
<script>
// @ is an alias to /src
import logInModal from "@/components/core/logInModal.vue";
import registerModal from "@/components/core/registerModal.vue";


import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { collection, setDoc, doc } from "firebase/firestore";


export default {
    name: 'HpmeView',
    components: {
        logInModal,
        registerModal
    },
    data() {
        return {
            email: null,
            login: null,
            register: null,
            windowWidth: null,
            mobileLogin: null,
            currentYear: new Date().getFullYear()
        }
    },
    methods: {
        toggleLogin() {
            this.login = !this.login
        },
        toggleRegister() {
            this.register = !this.register
        },
        checkScreen() {
            this.windowWidth = window.innerWidth;
            if (this.windowWidth <= 750) {
                this.mobileLogin = true;
                return;
            }
            this.mobileLogin = false;
            return;
        },
        async handleSubscribe() {

            const db = getFirestore();
            const timestamp = Date.now();

            const subscriptionRef = collection(db, "subscriptions");
            const newSubscriptionRef = doc(subscriptionRef);

            await setDoc(newSubscriptionRef, {
                id: timestamp,
                email: this.email
            });

            alert("Subscription Success ...")
        },
        Logout() {
            getAuth().signOut();
            // window.location.reload();
            this.$router.replace('/');
        },

    },
    created() {
        window.addEventListener("resize", this.checkSreen);
        this.checkScreen();
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
    }
}
</script>
<style scoped>
.whatsapp {
    margin: 10px;
    font-size: 35px;
    color: green !important;
}

.email {
    margin: 10px;
    font-size: 35px;
    color: #303030 !important;
}

ul {
    margin: 25px;
}

@media (max-width: 800px) {
    ul {
        width: 100%;
    }

    .email,
    .whatsapp {
        display: none;
    }
}</style>
  
  
  