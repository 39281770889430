<template>
  <router-view />
</template>
<script>


export default {
  name: 'App',

}
</script>
<style>
tr {
  padding: 5px gray;
}

.card-content {
  margin: 15px;
}
</style>
